.divComponente {
  display: flex !important;
  flex-direction: column !important;
  width: 100% !important;
  padding: 20px !important;
  background-color: #D1D1D1 !important;
}

.formComponente {
  display: flex !important;
  flex-direction: column !important;
  background-color: #fff !important;
  padding: 16px !important;
  border-radius: 8px !important;
  margin-right: 16px !important;
  margin-left: 16px !important;
}

.formComponenteCheckbox {
  display: flex !important;
  flex-direction: column !important;
  margin-right: 16px !important;
  margin-left: 16px !important;
}

.checkboxDisabled {
  pointer-events: none !important;
  opacity: 0.5 !important;
}

.titleComponente {
  font-size: 20px !important;
  line-height: 30px !important;
  font-weight: 600 !important;
  color: #1d3054 !important;
}

.descriptionComponente {
  font-size: 14px !important;
  line-height: 20px !important;
  color: #4d4d4d !important;
}

.gridComponente, .gridComponenteRow {
  display: flex !important;
  gap: 8px !important;
  width: 100% !important;
}

.gridComponente {
  margin-bottom: 16px !important;
  display: flex !important;
  flex-direction: column !important;
}

.gridComponenteRow {
  margin-bottom: 0 !important;
  display: flex !important;
  flex-direction: row !important;
}

.gridComponenteRadio {
  display: flex !important;
  flex-direction: column !important;
  gap: 0 !important;
  width: 100% !important;
  margin-bottom: 16px !important;
}

.gridComponenteNumber {
  display: flex !important;
  flex-direction: column !important;
  gap: 8px !important;
  width: 60% !important;
  margin-bottom: 16px !important;
}

.inputLabel {
  font-size: 14px !important;
}

.titleTypography {
  font-size: 16px !important;
  line-height: 24px !important;
  font-weight: 700 !important;
  color: #1d3054 !important;
}

.titleTooltip {
  font-size: 16px !important;
  line-height: 24px !important;
  font-weight: 700 !important;
  color: #1d3054 !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
}

.optionTooltip {
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-start !important;
  align-items: center !important;
}

.tooltipOutlined {
  font-size: 16px !important;
  cursor: pointer !important;
  color: #4D4D4D !important;
}

.textField {
  width: 100% !important;
  height: 40px !important;
  border: 1px !important;
  border-radius: 4px !important;
}

.textFieldDisabled {
  width: 100% !important;
  /* height: 40px !important; */
  border: 1px !important;
  border-radius: 4px !important;
  background-color: #E8E8E8 !important;
}

.textFieldDisabled input {
  color: #848484 !important;
}

.textFieldOutraNacionalidade {
  margin-bottom: 8px !important;
}

.divButtons {
  margin: 16px 16px 0px 16px !important;
  padding-bottom: 16px !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
}

.buttonSaveNext, .buttonSaveExit, .buttonDelete {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  border-radius: 8px !important;
  padding: 16px !important;
  width: 100% !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  text-align: center !important;
  height: 52px !important;
  text-transform: uppercase !important;
}

.buttonSaveNext:disabled, .buttonSaveExit:disabled {
  background-color: #D1D1D1 !important;
  color: #848484 !important;
  border: 2px solid #D1D1D1 !important;
}

.buttonSaveNext {
  background-color: #003DA5 !important;
  color: #fff !important;
}

.buttonSaveNext:hover {
  background-color: #002361 !important;
  color: #fff !important;
}

.buttonSaveExit {
  color: #003DA5 !important;
  border: 2px solid #003DA5 !important;
  background-color: #fff !important;
}

.buttonSaveExit:hover {
  color: #003DA5 !important;
  background-color: #c7c7c7 !important;
}

.buttonDelete {
  color: #B91C1C !important;
  border: 2px solid #B91C1C !important;
  background-color: #fff !important;
}

.buttonDelete:hover {
  color: #B91C1C !important;
  background-color: #c7c7c7 !important;
}

.asterisk {
  color: red !important;
}

.buttonAttach {
  display: flex !important;
  padding: 16px 0 !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 8px !important;
  align-self: stretch !important;
  border-radius: 8px !important;
  cursor: pointer !important;
  text-align: center !important;
  color: #1d3054 !important;
  border: 1px dashed #B1B3B3 !important;
  margin: 16px !important;
}

.buttonAttach h2 {
  font-size: 20px !important;
}

.buttonAttach p {
  font-size: 14px !important;
}

.card {
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-start !important;
  align-items: center !important;
  background-color: #fff !important;
  padding: 16px !important;
  border-radius: 8px !important;
  box-shadow: 0px 2px 4px -2px rgba(77, 77, 77, 0.06) !important, 0px 4px 8px -2px rgba(77, 77, 77, 0.10) !important;
  text-decoration: none !important;
  color: inherit !important;
  cursor: pointer !important;
  margin: 16px !important;
}

.icon {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background-color: #e8e8e8 !important;
  height: 40px !important;
  width: 40px !important;
  border-radius: 4px !important;
}

.cardTitle {
  font-size: 14px !important;
  font-weight: 700 !important;
  color: #1d3054 !important;
}

.cardSubtitle {
  font-size: 12px !important;
  color: #4d4d4d !important;
}

.chevronContainer {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 4px !important;
}

.cardClickable {
  width: 100% !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
}

.textContainer {
  flex: 1 !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  align-items: flex-start !important;
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.modalContainer {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  background-color: white !important;
  padding: 20px !important;
  border-radius: 8px !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important;
  max-width: 400px !important;
  width: 100% !important;
  text-align: center !important;
}

.modalTitle {
  font-size: 18px !important;
  font-weight: 700 !important;
  color: #B91C1C !important;
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.modalSubtitle {
  font-size: 14px !important;
  margin-bottom: 20px !important;
  color: #4D4D4D !important;
}

.modalButtons {
  margin-top: 20px !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  gap: 10px !important;
}

.modal-button-cancel {
  background-color: #f0f0f0 !important;
  color: #1D3054 !important;
  border: #1D3054 2px solid !important;
  width: 100% !important;
  border-radius: 8px !important;
  padding: 16px !important;
  font-weight: 700 !important;
  font-size: 16px !important;
}

.modal-button-delete {
  background-color: #B91C1C !important;
  color: white !important;
  border: none !important;
  width: 100% !important;
  border-radius: 8px !important;
  padding: 16px !important;
  font-weight: 700 !important;
  font-size: 16px !important;
}

.selectFieldDisabled {
  background-color: #E8E8E8 !important;
}